<template lang="pug">
.subject
  layoutContainer
    .video
      video(id="player-container-id" width="1200" height="675" preload="auto" playsinline webkit-playsinline)
      p(class="notice" v-if="!supportFreeWatch && !detail.buyFlag") 购买后可观看完整视频
    .detail
      .content
        .comp-info-basic-header
          .comp-info-basic-info 
            span.detail-name {{detail.name}}
            span.desc 7天无理由退款
          .purchase-btn
            p.price(v-if="!detail.buyFlag") ￥{{detail.price}}
            p.btn(@click="buy" v-if="!detail.buyFlag") 购买
        .comp-info-basic-dec
          .comp-info-basic 
            p.point
            p.sub-title 介绍：
          .curse-dec {{detail.introduction}}
        .comp-info-basic-dec
          .comp-info-basic 
            p.point
            p.sub-title 内容：
          .curse-content(v-html="detail.content")
    .author
      .title 作者
      .info(@click="checkAvatar")
        img.avatar(:src="detail.employeePhotoUrl")
        .name {{detail.employeeName}}
      .employee-introduction_visibility {{detail.employeeIntroduction}}
    .project-comment
      .project-comment-title
        p.point
        p.name 评价
      el-divider.el-divider
      .project-comment-content
        .project-comment-content-item(v-for="(item, index) in commentList" :key="`${index}projectComment`")
          .project-comment-content-item-basic
            .name
              .project-comment-content-item-basic-name {{item.userName}}
            .project-comment-content-item-basic-score(v-for="i in item.score" :key="`${i}111`")
              img(src="../../assets/start-comment.png" alt="")
            .project-comment-content-item-basic-score(v-for="i in (5 - item.score)" :key="`${i}222`")
            .project-comment-content-item-basic-time {{item.createTime}}
          .project-comment-content-item-basic-content
            span {{item.evaluation}}
            img(src="../../assets/guanbi2x.png" @click="handleDelete(item.id)" v-if="item.hasDeleteAuth")
        .comment-btn(@click="handleComment" v-if="detail.buyFlag") 写评价
    comment-dialog(
      :classId="detail.id",
      :showCommentDialog.sync="showDialog",
      @update="update"
    )
</template>

<script>
import {getCourseDetail, getCommentList, deleteComment, pay} from '@/api/subject'
import commentDialog from '@/components/subject/commentDialog.vue'
import {formatTimeFilter} from '../../utils/index'
export default {
  name: 'subjectDetail',
  data() {
    return {
      detail: '',
      commentList: [],
      supportFreeWatch: true,
      showDialog: false,
      winList: []
    }
  },
  components: {
    commentDialog
  },
  created() {
    const {id} = this.$route.query
    getCourseDetail({
      id
    }).then(res => {
      this.detail = res.data
      this.init()
      // this.player.src(this.detail.videoUrl) // url 播放地址
      console.log('this.detail.videoUrl: ', this.detail.videoUrl)
      this.supportFreeWatch = this.detail.supportFreeWatch
      this.$nextTick(() => {
        this.player = window.TCPlayer('player-container-id', {
          fileID: this.detail.fileId,
          appID: this.detail.appId,
          psign: this.detail.psign,
          autoplay: false,
          plugins: {
            ProgressMarker: true,
            ContextMenu: {
              statistic: true,
              levelSwitch: {
                open: true,
                switchingText: 'Start switching',
                switchedText: 'Switch success',
                switchErrorText: 'Switch fail'
              }
            }
          }
        }) // player-container-id 为播放器容器 ID，必须与 html 中一致
        this.player.one('playing', () => {
          var tipNode = document.createElement('div')
          if (!this.detail.buyFlag) {
            if (this.detail.freeWatchSeconds < 60) {
              tipNode.innerHTML = `可试看${this.detail.freeWatchSeconds}秒，购买后可观看完整视频`
            } else {
              tipNode.innerHTML = `可试看 ${Math.floor(this.detail.freeWatchSeconds / 60)}分${
                Math.ceil(this.detail.freeWatchSeconds % 60) > 0
                  ? `${Math.ceil(this.detail.freeWatchSeconds % 60)}秒`
                  : '钟'
              }，购买后可观看完整视频`
            }
            tipNode.className = 'tip-node'

            var tipIconClose = document.createElement('i')
            tipIconClose.className = 'tip-icon-close'
            tipIconClose.onclick = function() {
              tipNode.remove()
            }

            tipNode.appendChild(tipIconClose)
            this.player.el_.appendChild(tipNode)
          }
        })

        this.player.on('ended', () => {
          var pageNode = document.createElement('div')
          pageNode.className = 'page-node'

          pageNode.innerHTML = `<div class="page-content">
              <p>试看结束，购买后可观看完整视频</p>
              <button id="replay-btn">重新试看</button>
            </div>
          `
          this.player.el_.appendChild(pageNode)

          document.querySelector('#replay-btn').onclick = () => {
            this.player.play()
            pageNode.remove()
          }
        })
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    this.player.dispose()
    next()
  },
  methods: {
    buy() {
      const {id} = this.$route.query
      pay({id}).then(res => {
        if (res.code === 200009) {
          this.$router.push({
            path: '/login'
          })
        }
        const {code, data} = res
        if (code === 200 && data) {
          this.$router.push({
            path: '/pay',
            query: {
              courseId: id,
              orderId: res.data
            }
          })
        } else {
          this.$dialog.toast(res.msg)
        }
      })
    },
    init() {
      const params = {
        courseId: this.detail.id
      }
      getCommentList(params).then(res => {
        const data = res.data || []
        data.forEach(item => {
          item.createTime = item.createTime ? this.formatDate(item.createTime) : '2022-05-28'
        })
        this.commentList = data
      })
    },
    handleComment() {
      this.showDialog = true
    },
    handleDelete(id) {
      this.$confirm('确认删除？')
        .then(() => {
          deleteComment(id).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.init()
            }
          })
        })
        .catch(() => {})
    },
    checkAvatar() {
      this.$router.push({
        path: `/personal/detail/${this.detail.employeeId}`
      })
    },
    update() {
      this.init()
    },
    formatDate(time) {
      return formatTimeFilter(time, 'MM-DD HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;

.video {
  width: 1200px;
  overflow: hidden;
  height: 675px;
  background: #252525;
  position: relative;
  video {
    width: 100%;
  }
  .notice {
    position: absolute;
    left: 20px;
    bottom: 20px;
    color: #888888;
  }
}
.point {
  width: 12px;
  height: 12px;
  background: #5ad3cd;
  border-radius: 50%;
  margin-right: 10px;
}
.detail {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  .content {
    width: 100%;
  }
  .comp-info-basic-header {
    margin: 0 0 30px 0;
    box-sizing: content-box;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #eeeeee;
    line-height: 53px;
    padding-bottom: 30px;

    .detail-name {
      height: 37px;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 37px;
      margin-right: 30px;
    }
    .desc {
      text-align: center;
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      margin-right: 6px;
      word-break: keep-all;
    }
    .underline {
      text-decoration: underline;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
      line-height: 28px;
      word-break: keep-all;
      cursor: pointer;
    }
    .purchase-btn {
      .price {
        display: inline-block;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff724c;
        margin-right: 30px;
      }
      .btn {
        display: inline-block;
        width: 130px;
        height: 53px;
        background: #ff724c;
        border-radius: 27px;
        text-align: center;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .comp-info-basic-dec {
    margin: 0 0 30px 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
    font-size: 26px;

    .comp-info-basic {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }
    .sub-title {
      font-weight: bold;
    }
    .point {
      width: 12px;
      height: 12px;
      background: #5ad3cd;
      border-radius: 50%;
      margin-right: 10px;
    }
    .curse-dec {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-bottom: 30px;
    }
    .curse-content {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .comp-info-basic-header {
    margin: 0 0 30px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    .comp-info-basic {
      color: #999999;
      margin-right: 10px;
      white-space: nowrap;
    }
    .comp-info-basic-info {
      color: #333333;
      max-width: 700px;
      .detail {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5ad3cd;
        line-height: 25px;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .comp-info-basic-detail {
      margin-left: 10px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
    }
  }
}
.author {
  padding: 18px 30px 18px 0;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  .title {
    width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #5ad3cd;
    border-radius: 0px 100px 100px 0px;
    display: inline-block;
    margin-top: 2px;
    margin-right: 30px;
    color: #fff;
  }
  .info {
    display: inline-block;
    .avatar {
      width: 54px;
      height: 54px;
      border-radius: 70px;
      vertical-align: top;
      margin-right: 8px;
    }
    .name {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4bbbb6;
      line-height: 54px;
    }
  }
  .employee-introduction_visibility {
    display: inline-block;
    max-width: 897px;
  }
}
.project-comment {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 30px 54px 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;

  .project-comment-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    display: flex;
    align-items: center;
  }
  .el-divider {
    height: 1px;
    margin: 30px 0 40px 0;
  }
  .project-comment-content {
    .name {
      vertical-align: top;
      display: inline-block;
      width: 200px;
    }
    .project-comment-content-item-basic {
      position: relative;
      margin-bottom: 28px;
      .project-comment-content-item-basic-name {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
      }
      .project-comment-content-item-basic-objectName {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: -16px;
      }
      .project-comment-content-item-basic-score {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 2px 7px 3px 0;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
      }
      .project-comment-content-item-basic-time {
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        vertical-align: top;
        margin-left: 30px;
      }
    }
    .project-comment-content-item-basic-content {
      margin-bottom: 50px;
      span {
        display: inline-block;
        width: calc(100% - 64px);
        vertical-align: middle;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        margin-left: 42px;
        cursor: pointer;
      }
    }
    .comment-btn {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding: 4px 79px;
      background: #ffaa30;
      border-radius: 14px;
      width: 200px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
<style>
.tcplayer {
  margin: 0 auto;
}

.tip-node {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5em;
  left: 1em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 5px 10px;
}

.tip-icon-close {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 10px;
  background-image: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-close.png);
  background-size: cover;
  cursor: pointer;
}

.page-node {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  text-align: center;
}

.page-content a {
  display: flex;
  width: 120px;
  height: 28px;
  border-radius: 14px;
  color: #663d00;
  background-image: linear-gradient(90deg, #ffdf89 0, #f2ca5b 100%);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 auto;
}

.page-content #replay-btn::before {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-replay.png);
  background-size: contain;
}

.page-content #replay-btn {
  margin: 10px auto 0;
}
</style>
