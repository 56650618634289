<template lang="pug">
.content
  layoutContainer
    <div v-if="SearchInput" class="SearchInput"> {{ SearchInput }}
      <i @click="$store.commit('ChangeSearchInput', '')" :class="'el-icon-error close-icon'"></i>
    </div>
    checkRadioGroup(title="职业"
      :list="occupationList"
      :activeIndex.sync="queryParams.occupation"
      keyname="name"
    )
    checkRadioGroup(title="排序"
      :list="orderList"
      :activeIndex.sync="queryParams.order"
      :hideAll="true"
      keyname="name"
    )
    .project
      ul.course-list
        li.list(v-for="(item, index) in classList" :key="`${index}${item.id}`" @click="goToDetail(item.id)")
          .over
            img(:src="item.backgroundPicUrl", alt="", srcset="")
          .title {{item.introduction}}
          .class-title(:style="handleTitleColor(item.backgroundPicUrl)") {{item.name}}
          .hot-logo(v-if="item.hotNum > 0") 热门
          .user-info(@click="checkAuthor($event, item)")
            img.avatar(src="@/assets/avatar_icon.png", alt="", srcset="")
            .user-name {{item.employeeName}}
            img.icon(src="@/assets/time_icon.png", alt="", srcset="")
            .time {{computedTime(item.videoLength)}}
          .price(@click="pay($event, item.id)")
            span ￥{{item.price}}
            .pay(v-if="!item.buyFlag") 购买
      .empty-img
        img.empty(src="./../../assets/listempty.png" v-if="total === 0 && loaded" )
      .left-column
        .publish-options
          .publish-project_btn(@click="myClass" :class="{selected: isMyCourse}")
            img.my-course-icon(src="@/assets/course_icon.png", alt="", srcset="")
            span.my-course-text 我的课程
        .hot-project
          .title 
            img.hot-icon(src="@/assets/hot_icon.png", alt="", srcset="")
            span 热门
          ul
            li(
              v-for="item in hotCourseList",
              :key="item.id"
              @click="goToDetail(item.id)"
            )
              .project-hot 热
              .project-name {{item.name}}
              .project-budget ￥{{item.price}}
    el-pagination.pagination(
      v-if="total !== 0"
      background
      layout="prev, pager, next"
      :current-page.sync="page"
      :page-size="size"
      :total="total"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      @current-change="currentChange"
    )

</template>

<script>
import {subjectList, hotCourse, pay} from '@/api/subject'
import {getEmployeeOccupationListApi} from '@/api/index'
import {statusList} from '@/config/status.js'
import {setPriceNum, formatTimeFilter} from '@/utils/index'
import {mapState} from 'vuex'

export default {
  name: 'Project',
  data() {
    return {
      page: 1,
      size: 9,
      loaded: false,
      currentUserParticipateProjectStatus: '', // 当前用户参与项目状态 1 我发布的; 2 我申请的; 3 我竞标的; 4 我承接的
      projectList: [], // 项目数据 list
      total: 0,
      hotCourseList: [],
      occupationList: [],
      classList: [],
      id: '',
      name: '',
      queryParams: {
        order: '',
        occupation: '',
        isMyPublish: false
      },
      isMyCourse: false,
      orderList: [
        {
          name: '推荐',
          id: 1
        },
        {
          name: '最热',
          id: 2
        },
        {
          name: '最新',
          id: 3
        }
      ]
    }
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        if (this.checking) {
          this.checking = false
          return
        }
        this.subjectList()
      }
    },
    SearchInput(val) {
      this.name = val
      this.isMyCourse = false
      this.id = ''
      this.page = 1
      this.subjectList()
    },
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        this.queryParams.occupation = query.occupation_id ? query.occupation_id : ''
        this.queryParams.isMyPublish = query.isMyPublish ? true : false
        // 首页跳转，设置默认我的产品
        if (query.publishType == 1) {
          this.myClass()
        }
      }
    }
  },
  created() {
    this.id = this.$route.query.standardId
    this.getEmployeeOccupationListApi()
    this.subjectList()
    this.hotCourse()
  },
  computed: {
    ...mapState(['SearchInput'])
  },
  methods: {
    setPriceNum,
    formatTimeFilter,
    checkAuthor(e, info) {
      e.preventDefault()
      e.stopPropagation()
      this.$router.push({
        path: `/personal/detail/${info.employeeId}`
      })
    },
    computedTime(time) {
      console.log('time: ', time)
      let hour = 0
      let minute = 0
      hour = Math.floor(time / 60)
      minute = Math.ceil(time - hour * 60)

      return hour ? `${hour}小时${minute > 0 ? `${minute}分钟` : ''}` : `${minute}分钟`
    },
    getEmployeeOccupationListApi() {
      getEmployeeOccupationListApi().then(res => {
        this.occupationList = res.data
      })
    },
    handleTitleColor(url) {
      const imgName = url.split('/')[url.split('/').length - 1]
      let color = ''
      switch (imgName) {
        case '1.png':
          color = 'rgba(73, 93, 173, 1)'
          break
        case '2.png':
          color = 'rgba(255, 104, 31, 1)'
          break
        case '3.png':
          color = 'rgba(92, 43, 255, 1)'
          break
        default:
          color = '#fff'
          break
      }
      return {
        color
      }
    },
    subjectList() {
      this.loaded = false
      const {occupation, order, isMyPublish} = this.queryParams
      const params = {
        pageNum: this.page,
        pageSize: this.size,
        isMyCourse: this.isMyCourse,
        order: order || '',
        name: this.name,
        occupation,
        isMyPublish
      }
      if (this.id) {
        params.standardId = this.id
      }
      subjectList(params).then(res => {
        if (res.code === 200009) {
          this.$store.commit('ChangeSearchInput', '')
          this.$router.push({
            path: '/login'
          })
        }
        this.classList = res.data.records
        this.total = res.data.total
        this.loaded = true
        this.id = ''
      })
    },
    currentChange(page) {
      this.page = page
      this.subjectList()
    },
    setStatus(status) {
      return statusList[status]
    },
    hotCourse() {
      hotCourse().then(res => {
        this.hotCourseList = res.data
      })
    },
    resetQueryParams() {
      const queryParams = {
        statusTypes: '',
        businessTypes: '',
        entrustPartTypes: '',
        cooperationWayTypes: ''
      }
      this.$set(this, 'queryParams', queryParams)
    },
    pay(e, id) {
      e.preventDefault()
      e.stopPropagation()
      pay({id}).then(res => {
        if (res.code === 200009) {
          this.$router.push({
            path: '/login'
          })
        }
        const {code, data} = res
        if (code === 200 && data) {
          this.$router.push({
            path: '/pay',
            query: {
              courseId: id,
              orderId: res.data
            }
          })
        } else {
          this.$dialog.toast(res.msg)
        }
      })
    },
    goToDetail(id) {
      this.$store.commit('ChangeSearchInput', '')
      this.$router.push({
        path: '/techVideoDetail',
        query: {id: id}
      })
    },
    myClass() {
      this.name = ''
      this.isMyCourse = !this.isMyCourse
      this.page = 1
      this.$store.commit('ChangeSearchInput', '')
      this.subjectList()
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  > i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}

.content {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
}

.empty-img {
  margin: 0 auto;
}

.empty {
  width: 600px;
  display: block;
  margin: 80px auto;
}

.selected {
  border: 1px solid #5ad3cd !important;
  color: #5ad3cd !important;
}

.selected {
  border: 1px solid #5ad3cd !important;
  background: rgba(90, 211, 205, 1) !important;
  color: #5ad3cd !important;
}

.project {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 1205px;

  .course-list {
    width: 915px;
    overflow: hidden;
  }

  .list {
    // width: 24%;
    // margin-right: 1%;
    // padding: 20px 30px 20px 120px;
    // padding-bottom: 20px;
    width: 285px;
    margin-right: 20px;
    float: left;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 21px;
    border-radius: $radius;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .over {
      width: 100%;

      img {
        width: 100%;
        display: block;
      }
    }

    .class-title {
      position: absolute;
      top: 48px;
      left: 12px;
      width: 165px;
      color: #fff;
      font-weight: 900;
      font-size: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //将对象作为弹性伸缩盒⼦模型显⽰。
      -webkit-box-orient: vertical; //从上到下垂直排列⼦元素（设置伸缩盒⼦的⼦元素排列⽅式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上⾯两个属性，表⽰显⽰的⾏数。此处为3⾏
    }

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #666666;
      height: 50px;
      line-height: 25px;
      margin: 14px 16px 15px 16px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //将对象作为弹性伸缩盒⼦模型显⽰。
      -webkit-box-orient: vertical; //从上到下垂直排列⼦元素（设置伸缩盒⼦的⼦元素排列⽅式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上⾯两个属性，表⽰显⽰的⾏数。此处为3⾏
      word-wrap: break-word; //允许单词内断句，⾸先会尝试挪到下⼀⾏，看看下⼀⾏的宽度够不够，不够的话就进⾏单词内的断句
    }

    .user-info {
      margin: 0 0 16px 16px;
      position: relative;
    }

    .avatar {
      width: 15px;
      height: 17px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 4px;
    }

    .icon {
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }

    .user-name {
      display: inline-block;
      vertical-align: top;
      line-height: 20px;
      font-size: 18px;
      margin-right: 20px;
      color: #666666;
    }

    .time {
      display: inline-block;
      vertical-align: top;
      color: #666666;
      // position: absolute;
      // right: 10px;
      // top: 0;
      line-height: 20px;
    }

    .price {
      margin: 0 0 16px 16px;
      position: relative;
      color: #ff724c;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 33px;

      .pay {
        width: 64px;
        height: 30px;
        position: absolute;
        right: 16px;
        top: 2px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        background: #ff724c;
        border-radius: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .left-column {
    width: 290px;

    .publish-options,
    .hot-project {
      background: #fff;
      margin-bottom: 16px;
      box-sizing: border-box;
      border-radius: $radius;
    }

    .hot-project {
      padding: 20px 15px 8px;

      .title {
        font-size: 18px;
        font-family: $fontFamily;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .hot-icon {
          width: 22px;
          height: 22px;
          margin-right: 6px;
        }
      }

      ul {
        padding-top: 15px;
        border-top: 2px solid #dcdfe6;

        li {
          margin-bottom: 13px;
          cursor: pointer;

          .project-type {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
            background-color: $btnColor;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            vertical-align: top;
          }

          .project-hot {
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            background: rgba(247, 72, 25, 0.1);
            border-radius: 2px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #f74819;
            margin-right: 6px;
          }

          .project-name {
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            display: inline-block;
            overflow: hidden;
            max-width: 180px;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: top;
          }

          span {
            height: 16px;
            display: inline-block;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5ad3cd;
            line-height: 13.5px;
            border: 1px solid $btnColor;
            padding: 0 5px;
            margin-left: 6px;
            margin-top: 2px;
            border-radius: 10px;
            vertical-align: top;
          }

          .project-budget {
            float: right;
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 500;
            color: #ff724c;
            line-height: 20px;
          }
        }
      }
    }

    .publish-options {
      padding: 20px 15px;
      overflow: hidden;
      margin-bottom: 10px;

      .publish-project_btn {
        width: 260px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        font-family: $fontFamily;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid #eeeeee;
        background: rgba(90, 211, 205, 0.6);
        border-radius: 4px;
      }

      .my-course-icon {
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 6px;
        padding-top: 15px;
        box-sizing: content-box;
      }

      .my-course-text {
        vertical-align: top;
        color: #ffffff;
      }

      .publish-type_list {
        width: 123px;
        height: 48px;
        font-size: 18px;
        border: 1px solid #eeeeee;
        color: #999999;
        font-family: $fontFamily;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        margin-bottom: 20px;
        float: left;
        cursor: pointer;
        border-radius: $radius;

        &:nth-child(2n) {
          margin-right: 10px;
        }
      }

      // .publish-type_list:nth-child(1) {
      //   margin-right: 10px;
      // }
    }
  }
}

.hot-logo {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #f74819;
  border-radius: 8px 30px 30px 8px;
  padding-left: 10px;
  padding-right: 13px;
}

.pagination {
  text-align: center;
}
</style>
